<template>
  <v-container>
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" lg="3" md="3" sm="12">
        <h2>Documents</h2>
        <v-sheet rounded="lg">
          <v-list color="transparent">
            <v-list-item
              v-for="(doc, index) in docLists"
              :key="doc.key"
              @click="selectKeySelection(index)"
              :to="doc.goTo"
            >
              <v-list-item-content>
                <v-list-item-title> {{ doc.title }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>

      <v-col cols="12" lg="9" md="9" sm="12">
        <h2>{{ topic }}</h2>
        <v-sheet min-height="70vh" rounded="lg">
          <div v-if="keySection == 'register'">
            <BinanceRegisterKeySection />
          </div>
          <div v-if="keySection == 'verify'">
            <BinanceVerifySection />
          </div>
          <div v-if="keySection == 'apikey'">
            <ApiBinanceKeySection />
          </div>
          <div v-if="keySection == 'registerbot'">
            <RegisterCryptoMationBot />
          </div>
          <div v-if="keySection == 'connectbot'">
            <CryptoMationConnectBot />
          </div>
          <div v-if="keySection == 'transfermoney'">
            <TransferMoneyToWalet />
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiBinanceKeySection from "./apiBinanceKeySection";
import BinanceRegisterKeySection from "./binanceRegisterKeySection.vue";
import BinanceVerifySection from "./binanceVerifySection.vue";
import CryptoMationConnectBot from "./cryptoMationConnectBot";
import TransferMoneyToWalet from "./transferMoneyToWallet";
import RegisterCryptoMationBot from "./registerCryptoMationBot.vue";
// https://jareklipski.medium.com/linking-to-a-specific-tab-in-vuetify-js-d978525f2e1a
export default {
  name: "DocumentsPage",
  components: {
    ApiBinanceKeySection,
    BinanceRegisterKeySection,
    BinanceVerifySection,
    CryptoMationConnectBot,
    TransferMoneyToWalet,
    RegisterCryptoMationBot,
  },
  data: () => ({
    keySection: "",
    topic: "",
    docLists: [
      {
        // title: "API Keys: Binance Features",
        title: "1. เปิดใช้งาน Crypto-Mation Trading Bot",
        key: "registerbot",
        goTo: "/docs/registerbot",
      },
      {
        // title: "Binance Register",
        title: "2. สมัครสมาชิก Binance",
        key: "register",
        goTo: "/docs/register",
      },
      {
        title: "3. ยืนยันตัวตน Binance",
        key: "verify",
        goTo: "/docs/verify",
      },
      {
        // title: "API Keys: Binance Features",
        title: "4. สร้าง Binance API Key",
        key: "apikey",
        goTo: "/docs/apikey",
      },
      {
        // title: "API Keys: Binance Features",
        title: "5. เชื่อมต่อบอทกับ Binance",
        key: "connectbot",
        goTo: "/docs/connectbot",
      },
      {
        // title: "API Keys: Binance Features",
        title: "6. โอนเงินเข้า USDⓈ-M Futures",
        key: "transfermoney",
        goTo: "/docs/transfermoney",
      },
    ],
    docListKey: [
      "registerbot",
      "register",
      "verify",
      "features",
      "connectbot",
      "transfermoney",
    ],
  }),
  methods: {
    selectKeySelection(keyIndex) {
      this.keySection = this.docLists[keyIndex].key;
      this.topic = this.docLists[keyIndex].title;
    },
  },
  computed: {},
  mounted() {
    if (
      this.$route.params.section !== undefined &&
      this.$route.params.section.length > 0
    ) {
      if (this.docListKey.includes(this.$route.params.section)) {
        this.keySection = this.$route.params.section;
        let obj = this.docLists.find((o) => o.key === this.keySection);
        this.topic = obj.title;
      }
    } else {
      this.keySection = this.docLists[0].key;
      this.topic = this.docLists[0].title;
    }
  },
};
</script>
