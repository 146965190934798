<template>
  <section class="pa-5">
    <h1>ขั้นตอนการโอนเงินจาก Funding Wallet ไปที่ USDⓈ-M Futures Wallet</h1>
    <v-divider class="pb-2"></v-divider>
    <center>
      <LazyYoutube
        ref="lazyVideo"
        src="https://www.youtube.com/embed/al5dZ2Me_Bw"
      />
    </center>
    <v-divider class="pb-2 mt-2"></v-divider>

    <p>
      เมื่อทำการซื้อ USDT ผ่าน P2P เสร็จเรียบร้อยแล้ว USDT จะเข้าไปอยู่ใน
      Funding Wallet<br />
      ในการใช้งานบอทนั้นจะต้องทำการโอนเงินจาก Funding Wallet ไปที่ Spot Wallet
      และไปที่ USDⓈ-M Futures Wallet โดยสามารถทำตามขั้นตอนได้ดังนี้
    </p>
    <p>
      1. ไปที่เมนู Wallet ที่ด้านขวาล่างของ Application<br />
      2. ด้านบนเลือก Funding<br />
      3. เลือก Transfer
    </p>
    <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/transfer-mony-01.jpg')"
        contain
        max-width="200"
      />
    </div>
    <br />
    <p>
      4. เลือกจากกระเป๋า Funding<br />
      5. เลือกโอนไปที่ Spot Wallet<br />
      6. เลือกเหรียญที่จะโอนเป็น USDT<br />
      7. ใส่จำนวนเงินที่ต้องการโอน<br />
      8. ยืนยันการโอนโดยกดปุ่ม Confirm Transfer
    </p>
    <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/transfer-mony-02.jpg')"
        contain
        max-width="200"
      />
    </div>
    <br />
    <p>
      9. ด้านบนเลือก Spot<br />
      10. เลือก Transfer
    </p>
    <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/transfer-mony-03.jpg')"
        contain
        max-width="200"
      />
    </div>
    <br />
    <p>
      11. เลือกจากกระเป๋า Spot Wallet<br />
      12. เลือกโอนไปที่ USDⓈ-M Futures Wallet<br />
      13. เลือกเหรียญที่จะโอนเป็น USDT<br />
      14. ใส่จำนวนเงินที่ต้องการโอน<br />
      15. ยืนยันการโอนโดยกดปุ่ม Confirm Transfer
    </p>
    <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/transfer-mony-04.jpg')"
        contain
        max-width="200"
      />
    </div>
    <br />
    <p>
      ทำการตรวจสอบยอดเงินโดย<br />
      16. ด้านบนเลือก Future<br />
      17. กระเป๋าย่อยเลือก USDⓈ-M<br />
      18. ทำการตรวจสอบยอดเงินหากมียอดเงินแล้วเป็นอันเสร็จเรียบร้อย
    </p>
    <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/transfer-mony-05.jpg')"
        contain
        max-width="200"
      />
    </div>

    <p class="caption ma-0">Last update: 29/04/2565</p>
  </section>
</template>

<script>
import { LazyYoutube } from "vue-lazytube";
export default {
  name: "TransferMoneyToWalet",
  data: () => ({}),
  methods: {},
  computed: {},
  components: { LazyYoutube },
};
</script>
<style scoped>
.image-center-view {
  display: flex;
  justify-content: center;
}
</style>
