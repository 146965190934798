<template>
  <section class="pa-5">
    <h1>ขั้นตอนการสมัครบัญชี Binance Exchange</h1>
    <p>
      1. ไปยัง
      <a href="https://www.binance.com/" target="_blank">
        <span class="blue--text text--lighten-1"> เว็บไซด์ Binance </span>
      </a>
      กดปุ่ม Register เลือกประเทศเป็นประเทศไทยและสร้างบัญชีส่วนตัว (Create
      Personal Account)
    </p>
    <!-- -->
    <v-img :src="require('../../assets/docs/regis-binance-1.png')" />
    <p class="mt-2">
      2. กรอกอีเมลล์ (E-mail) และรหัสผ่าน (Password) แลัวกด Next
    </p>
    <p>การตั้งรหัสผ่านมีคำแนะนำดังนี้</p>
    <ul>
      <li>ตัวอักษรภาษาอังกฤษอย่างน้อย 8 ตัวอักษร</li>
      <li>ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร</li>
      <li>ตัวเลขอย่างน้อย 1 ตัว</li>
    </ul>
    <div class="image-center-view">
      <v-img
        class="mt-2"
        :src="require('../../assets/docs/regis-binance-2.png')"
        contain
        max-width="300"
      />
    </div>
    <p>
      3. Binance
      จะส่งตัวเลขยืนยันอีเมลล์ไปยังอีเมล์ของท่านที่สมัครให้คัดลอกไปใส่แล้วกด
      Next
    </p>
    <!-- -->
    <div class="image-center-view">
      <v-img
        :src="require('../../assets/docs/regis-binance-3.png')"
        max-width="400"
      />
    </div>
    <p class="mt-2">
      4. กรอกเบอร์โทรศัพท์ของท่านและกดส่ง
      Binanace จะส่งข้อความยืนยันเบอร์โทรศัพท์มาแลัวกด Next
      เท่านั้นท่านก็จะมีบัญชี Binance Exchange เรียบร้อย
    </p>
    <!-- -->
    <div class="image-center-view">
      <v-img
        :src="require('../../assets/docs/regis-binance-4.png')"
        max-width="400"
      />
    </div>
    <p class="caption ma-0">Last update: 22/03/2565</p>
  </section>
</template>

<script>
export default {
  name: "BinanceRegisterKeySection",
  data: () => ({}),
  methods: {},
  computed: {},
};
</script>

<style scoped>
.image-center-view {
  display: flex;
  justify-content: center;
}
</style>
