<template>
  <section class="pa-5">
    <h1>ขั้นตอนการสร้างคีย์เชื่อมต่อระหว่าง Binanace กับ Crypto-Mation</h1>
    <v-divider class="pb-2"></v-divider>
    <center>
      <LazyYoutube
        ref="lazyVideo"
        src="https://www.youtube.com/embed/JDaKfZc_JRA"
      />
    </center>
    <v-divider class="pb-2 mt-2"></v-divider>

    <h4>1. เปิดกระเป๋า Future</h4>
    <p>
      เข้าสู่ระบบด้วยบัญชีที่สมัครและถูกยืนยันตัวตนแล้ว
      จากนั้นไปยังที่กระเป๋าตัง (Wallet) -> Features. และคลิก "Open Now"
    </p>
    <v-img
      class="my-2"
      :src="require('../../assets/docs/api-binance-1.png')"
      contain
    />
    <v-img
      class="my-2"
      :src="require('../../assets/docs/api-binance-4.png')"
      contain
    />
    <!-- iamge -->
    <h4>2. สร้าง API Key</h4>
    <p>ไปยัง profile -> API Management ตั้งชื่อ API ey และกดปุ่ม Create</p>
    <div class="image-center-view">
      <v-img
        class="my-2"
        :src="require('../../assets/docs/api-binance-2.png')"
        contain
      />
    </div>

    <p>
      กดปุ่ม "Edit restrctions" และติ๊กถูก "Enable Features" และกดบันทึก (Save)
      <b class="red--text"> *หมายเหตุ อย่าพึ่งเปิดหน้าเว็บ</b> จะเห็นมี
      <b> API KEY </b> และ <b> Secret Key </b> ที่จะนำไปเชื่อมต่อ Crypto Mation
      Trading Bot
      <b class="red--text"
        >(หรือบันทึก QR CODE เป็นรูปภาพ แต่ไม่แนะนำเนื่องจากเผยแพร่ไปได้ง่าย)
      </b>
    </p>

    <v-img
      class="my-2"
      :src="require('../../assets/docs/api-binance-5.png')"
      contain
    />
    <p class="caption ma-0">Last update: 29/04/2565</p>
  </section>
</template>

<script>
import { LazyYoutube } from "vue-lazytube";
export default {
  name: "ApiBinanceKeySection",
  data: () => ({}),
  methods: {},
  computed: {},
  components: { LazyYoutube },
};
</script>
<style scoped>
.image-center-view {
  display: flex;
  justify-content: center;
}
</style>
